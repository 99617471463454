<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-50 md-small-size-100" v-if="diario && ventas && diario">
      <p class="md-headline">Libro de compras, venta y diario ya han sido ingresados</p>
    </div>
      <div class="md-layout-item md-size-50 md-small-size-100">
      <form novalidate @submit.prevent="validateLibroCompras">
        <md-card v-if="!compras" >
          <md-card-header>
            <md-card-header-text>
              <div class="md-title">Nuevo libro compras</div>
              <p class="md-caption">Clicke en "Guardar cambios" al ingresar datos.</p>
            </md-card-header-text>
            <md-menu md-size="big" md-direction="bottom-end">
              <md-button class="md-icon-button" @click="go_to('/')">
                <md-icon>keyboard_backspace</md-icon>
              </md-button>
            </md-menu>
          </md-card-header>
          <md-card-content>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('authorization')">
                  <label for="authorization" >Autorización</label>
                  <md-input type="text" name="authorization"
                  id="authorization"
                  v-model="instance.libro_compras_obj.authorization" :disabled="sending" />
                  <span class="md-error"
                  v-if="!$v.instance.libro_compras_obj.authorization.required">
                El núnmero de autorización es requerido</span>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <md-field
                class="md-focused"
                :class="getValidationClass('date_authorization')">
                  <label for="date_authorization" >Fecha autoriación</label>
                  <md-input type="date" name="date_authorization"
                  id="date_authorization"
                    v-model="instance.libro_compras_obj.date_authorization"
                  :disabled="sending" />
                  <span class="md-error"
                  v-if="!$v.instance.libro_compras_obj.date_authorization.required">
                La fecha de autorización es requerido</span>
                </md-field>
              </div>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field >
                  <label for="year">Año ejercicio fiscal</label>
                  <md-input type="text" name="purchase_year"
                  id="year" v-model="instance.libro_compras_obj.year"
                  disabled="disabled" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <br>
                <md-switch v-model="instance.libro_compras_obj.status">
                  <span v-if="instance.libro_compras_obj.status">Activo</span>
                  <span v-if="!instance.libro_compras_obj.status">Inactivo</span>
                </md-switch>
              </div>
            </div>
          </md-card-content>
          <md-progress-bar md-mode="indeterminate" v-if="sending" />
          <span class="msj" v-if="purchase">
            Libro de compras creado!!!</span>
          <md-card-actions>
            <md-button
            type="submit" class="md-primary"
            :disabled="sending">Guardar cambios</md-button>
          </md-card-actions>
        </md-card>
        <md-snackbar
        :md-active.sync="purchaseSaved">
        El documento {{ docuemt }} ha sido actualizado!</md-snackbar>

      </Form>
      </div>
      <div class="md-layout-item md-size-50 md-small-size-100">
        <form novalidate @submit.prevent="validateLibroVentas">
          <md-card v-if="!ventas" >
            <md-card-header>
              <md-card-header-text>
                <div class="md-title">Nuevo libro venta</div>
                <p class="md-caption">Clicke en "Guardar cambios" al ingresar datos.</p>
              </md-card-header-text>
              <md-menu md-size="big" md-direction="bottom-end">
                <md-button class="md-icon-button" @click="go_to('/')">
                  <md-icon>keyboard_backspace</md-icon>
                </md-button>
              </md-menu>
            </md-card-header>
            <md-card-content>
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-small-size-100">
                  <md-field :class="getValidationClassVentas('authorization')">
                    <label for="authorization" >Autorización</label>
                    <md-input type="text" name="authorization"
                    id="authorization"
                      v-model="instance.libro_ventas_obj.authorization" :disabled="sending" />
                    <span class="md-error"
                    v-if="!$v.instance.libro_ventas_obj.authorization.required">
                  El núnmero de autorización es requerido</span>
                  </md-field>
                </div>
                <div class="md-layout-item md-small-size-100">
                  <md-field
                  class="md-focused"
                  :class="getValidationClassVentas('date_authorization')">
                    <label for="date_authorization" >Fecha autorización</label>
                    <md-input type="date" name="date_authorization"
                    id="date_authorization" v-model="instance.libro_ventas_obj.date_authorization"
                    :disabled="sending" />
                    <span class="md-error"
                    v-if="!$v.instance.libro_ventas_obj.date_authorization.required">
                  La fecha de autorización es requerido</span>
                  </md-field>
                </div>
              </div>
              <div class="md-layout md-gutter">
                <div class="md-layout-item md-small-size-100">
                  <md-field >
                    <label for="year">Año ejercicio fiscal</label>
                    <md-input type="text" name="sell_year"
                    id="year" v-model="instance.libro_ventas_obj.year"
                    disabled="disabled" />
                  </md-field>
                </div>
                <div class="md-layout-item md-small-size-100">
                  <br>
                  <md-switch v-model="instance.libro_ventas_obj.status">
                    <span v-if="instance.libro_ventas_obj.status">Activo</span>
                    <span v-if="!instance.libro_ventas_obj.status">Inactivo</span>
                  </md-switch>
                </div>
              </div>
            </md-card-content>
            <md-progress-bar md-mode="indeterminate" v-if="sending" />
            <span class="msj" v-if="sale">
              Libro de ventas creado!!!</span>
            <md-card-actions>
              <md-button
              type="submit" class="md-primary" :disabled="sending">Guardar cambios</md-button>
            </md-card-actions>
          </md-card>
          <md-snackbar
            :md-active.sync="saleSaved">
            El documento {{ docuemt }} ha sido actualizado!</md-snackbar>

         </form>
        </div>
      <div class="md-layout-item md-size-50 md-small-size-100 margin">
      <form novalidate @submit.prevent="validateLibroDiario">
        <md-card v-if="!diario">
          <md-card-header>
            <md-card-header-text>
              <div class="md-title" >Nuevo libro diario</div>
              <p class="md-caption">Clicke en "Guardar cambios" al ingresar datos.</p>
            </md-card-header-text>
            <md-menu md-size="big" md-direction="bottom-end">
              <md-button class="md-icon-button" @click="go_to('/')">
                <md-icon>keyboard_backspace</md-icon>
              </md-button>
            </md-menu>
          </md-card-header>
          <md-card-content>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClassDiario('authorization')">
                  <label for="authorization" >Autorización</label>
                  <md-input type="text" name="authorization"
                  id="authorization"
                    v-model="instance.libro_diario_obj.authorization" :disabled="sending" />
                  <span class="md-error"
                  v-if="!$v.instance.libro_diario_obj.authorization.required">
                  El número de autorización es requerido</span>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <md-field
                class="md-focused"
                :class="getValidationClassDiario('date_authorization')">
                  <label for="date_authorization" >Fecha autorización</label>
                  <md-input type="date" name="date_authorization"
                    id="date_authorization"
                    v-model="instance.libro_diario_obj.date_authorization"
                  :disabled="sending" />
                  <span class="md-error"
                  v-if="!$v.instance.libro_diario_obj.date_authorization.required">
                La fecha de autorización es requerido</span>
                </md-field>
              </div>
            </div>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-small-size-100">
                <md-field >
                  <label for="year">Año ejercicio fiscal</label>
                  <md-input type="text" name="year"
                    id="year" v-model="instance.libro_diario_obj.year"
                    disabled="disabled" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <br>
                <md-switch v-model="instance.libro_diario_obj.status">
                  <span v-if="instance.libro_diario_obj.status">Activo</span>
                  <span v-if="!instance.libro_diario_obj.status">Inactivo</span>
                </md-switch>
              </div>
            </div>
          </md-card-content>
          <md-progress-bar md-mode="indeterminate" v-if="sending" />
          <span class="msj" v-if="diary">
            Libro diario creado!!!</span>
          <md-card-actions>
            <md-button
            type="submit" class="md-primary" :disabled="sending">Guardar cambios</md-button>
          </md-card-actions>
        </md-card>
        <md-snackbar
          :md-active.sync="diarySaved">El documento {{ docuemt }} ha sido actualizado!</md-snackbar>
      </form>
      </div>
  </div>
</template>
<script>
/* eslint-disable */
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
} from 'vuelidate/lib/validators'

export default {
  name: 'FormBooks',
  created(){
    this.compras  = localStorage.getItem('book_purchase')
    this.ventas  = localStorage.getItem('book_sale')
    this.diario = localStorage.getItem('book_diary')
  },
  mixins: [validationMixin],
  data() {
    return {
      list_libros: [],
      url_ventas: 'sale_book/',
      url_compras: 'purchase_book/',
      url_diario: 'diary_book/',
      base: process.env.VUE_APP_BASE_URL,
      instance: {
        libro_compras_obj:{
          id: null,
          authorization: null,
          date_authorization: null,
          status: true,
        },
        libro_ventas_obj:{
          id: null,
          authorization: null,
          date_authorization: null,
          status: true,
        },
        libro_diario_obj:{
          id: null,
          authorization: null,
          date_authorization: null,
          status: true,
        }
      },
      choosen_year: [
        {value:'2021', text: '2021'},
        {value:'2022', text: '2022'},
        {value:'2023', text: '2023'},
        {value:'2024', text: '2024'},
        {value:'2025', text: '2025'},
        {value:'2026', text: '2026'},
        {value:'2027', text: '2027'},
        {value:'2028', text: '2028'},
        {value:'2029', text: '2029'},
      ],
      purchaseSaved: false,
      saleSaved: false,
      diarySaved: false,
      purchase: false,
      sale: false,
      diary: false,
      sending: false,
      docuemt: null,
      compras: null,
      ventas: null,
      diario: null
    };
  },
  validations: {
    instance: {
      libro_compras_obj: {
        authorization: {
          required,
        },
        date_authorization: {
          required,
        },
        status: {
          required,
        },
      },
      libro_ventas_obj: {
        authorization: {
          required,
        },
        date_authorization: {
          required,
        },
        status: {
          required,
        },
      },
      libro_diario_obj: {
        authorization: {
          required,
        },
        date_authorization: {
          required,
        },
        status: {
          required,
        },
      }
    },
  },
  methods: {
    go_to(url) {
      this.$router.push(url);
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    handlerError: (error) => {
      const result = error.response.data;
      const self = this;
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(([key, value]) => { window.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
        return false;
      }
      this.$swal.fire('Error!', result, 'error');
      return false;
    },
    list() {
      let payload = {}
      const self = this;
      this.sendRequest(payload, this.url).then((data) => {
        self.list_data = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    getValidationClass (fieldName) {
      const field = this.$v.instance.libro_compras_obj[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    getValidationClassVentas (fieldName) {
      const field = this.$v.instance.libro_ventas_obj[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    getValidationClassDiario (fieldName) {
      const field = this.$v.instance.libro_diario_obj[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm () {
      this.$v.$reset()
    },
    validateLibroCompras () {
      this.$v.$touch()
      if (!this.$v.instance.libro_compras_obj.$invalid) {
        this.saveCompras()
      }
    },
    validateLibroVentas () {
      this.$v.$touch()
      if (!this.$v.instance.libro_ventas_obj.$invalid) {
        this.saveVentas()
      }
    },
    validateLibroDiario () {
      this.$v.$touch()
      if (!this.$v.instance.libro_diario_obj.$invalid) {
        this.saveDiario()
      }
    },
    saveCompras () {
      const payload = this.instance.libro_compras_obj
      const self = this
      this.sending = true

      payload.company = this.$store.state.company_obj.id
      payload.store = this.$store.state.store_obj.id
      payload.fiscal_year = localStorage.getItem('id_fiscal_year')

      this.postRequestCompras(payload).then(function (data) {
        self.docuemt = `${self.instance.libro_compras_obj.authorization}`
        self.purchaseSaved = true
        self.purchase = true
        self.sending = false
        self.instance.libro_compras_obj = data.data
        self.save_storage('book_purchase', self.instance.libro_compras_obj)
      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    saveVentas () {
      const payload = this.instance.libro_ventas_obj
      const self = this
      this.sending = true

      payload.company = this.$store.state.company_obj.id
      payload.store = this.$store.state.store_obj.id
      payload.fiscal_year = localStorage.getItem('id_fiscal_year')

      this.postRequestVentas(payload).then(function (data) {
        self.docuemt = `${self.instance.libro_ventas_obj.authorization}`
        self.saleSaved = true
        self.sale = true
        self.sending = false
        self.instance.libro_ventas_obj = data.data
        self.save_storage('book_sale', self.instance.libro_ventas_obj)
      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    saveDiario () {
      const payload = this.instance.libro_diario_obj
      const self = this
      this.sending = true

      payload.company = this.$store.state.company_obj.id
      payload.store = this.$store.state.store_obj.id
      payload.fiscal_year = localStorage.getItem('id_fiscal_year')

      this.postRequestDiario(payload).then(function (data) {
        self.docuemt = `${self.instance.libro_diario_obj.authorization}`
        self.diarySaved = true
        self.diary = true
        self.sending = false
        self.instance.libro_diario_obj = data.data
        self.save_storage('book_diary', self.instance.libro_diario_obj)
      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    async retrieveInvoice () {
      const self = this
      let payload = {
        id: this.instance.id,
      }
      await this.retrieveRequest(this.url, payload).then(function (data) {
        self.instance = data.data
      }).catch(function (error) {
        self.handlerError(error)
      })
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async postRequestCompras (payload) {
      const result = await this.$http.post(this.url_compras, payload)
      return result
    },
    async postRequestVentas (payload) {
      const result = await this.$http.post(this.url_ventas, payload)
      return result
    },
    async postRequestDiario (payload) {
      const result = await this.$http.post(this.url_diario, payload)
      return result
    },
    async putRequest (payload) {
      const result = await this.$http.put(`${this.url}${payload.id}/`, payload)
      return result
    },
    async retrieveRequest (url, _payload) {
      const result = await this.$http.get(url + _payload.id + '/', { params: _payload })
      return result
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    }
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
<style lang="css" scoped>
 .success {
    font-weight: 500;
    background-color: #4aad09;
  }
  .warning {
    font-weight: 500;
    background-color: yellow;
  }
  .danger {
    font-weight: 500;
    background-color: red;
  }
  table {
    font-size: 2em;
  }
  .margin{
    margin-top: 12px;
    margin-bottom: 50px;
  }
  .msj{
    margin-left: 15px;
    font-weight: 500;
    color: #4aad09;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #424242 inset;
  }
</style>
